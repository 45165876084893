var lang = document.documentElement.lang;


function initMap() {
  var mapDiv = document.getElementById('map');
  myLatLng = new google.maps.LatLng(45.581228, -73.455093);
  myLatLng2 = new google.maps.LatLng(45.581228, -73.555093);
  var map = new google.maps.Map(mapDiv, {
      center: myLatLng2,
      zoom: 13
  });
  var marker = new google.maps.Marker({
  position: myLatLng,
  map: map,
  title: ''
});

map.setOptions({styles: styles});
}


function initMapStore(lat,lng) {
  var mapDiv = document.getElementById('map-store');
  myLatLng = new google.maps.LatLng(lat, lng);

  var map = new google.maps.Map(mapDiv, {
      center: myLatLng,
      zoom: 13
  });
  var marker = new google.maps.Marker({
  position: myLatLng,
  map: map,
  title: ''
});

map.setOptions({styles: styles});

}





function initMapStoreLocator() {
  myLatLng2 = new google.maps.LatLng(45.501228, -73.555093);
  map = new google.maps.Map(document.getElementById('map-store-locator'), {
    center: myLatLng2,
    zoom: 12,
    gestureHandling: 'greedy', 
    zoomControl: true,
    zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
    },
    streetViewControl: true,
    streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
    },
  });
  map.setOptions({styles: styles});
  setMarkers(map);



  // Create the search box and link it to the UI element.
  var input = document.getElementById('pac-input');
  var searchBox = new google.maps.places.SearchBox(input);
 //map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);


  // Bias the SearchBox results towards current map's viewport.
  map.addListener('bounds_changed', function() {
    searchBox.setBounds(map.getBounds());
  }); 

  searchBox.addListener('places_changed', function() {
    var places = searchBox.getPlaces();

    if (places.length == 0) {
      return;
    }

 
    var bounds = new google.maps.LatLngBounds();
    places.forEach(function(place) {
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
        nearMarkers(place, marker_array);
        bounds.extend(markers_near.position);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  });


  // geolocation
  if (navigator.geolocation) {
   
    navigator.geolocation.getCurrentPosition(function(position) {
      
      var pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      map.setCenter(pos);
   
    });
  } else {
    // Browser doesn't support Geolocation
  }

  
}


var styles = [
  {
    stylers: [
      
      { saturation: -100 }
    ]
  }
];

function rad(x) {return x*Math.PI/180;}
function nearMarkers( place, marker_array ) {
 
    var lat = place.geometry.location.lat();
    var lng = place.geometry.location.lng();
    var R = 6371; // radius of earth in km
    var distances = [];
    var closest = -1;
    for( i=0;i<marker_array.length; i++ ) {
        var mlat = marker_array[i].position.lat();
        var mlng = marker_array[i].position.lng();
        var dLat  = rad(mlat - lat);
        var dLong = rad(mlng - lng);
        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c;
        distances[i] = d;
        if ( closest == -1 || d < distances[closest] ) {
            closest = i;
        }
    }
    markers_near = marker_array[closest];

    return markers_near;
}

var marker_array = new Array();
function setMarkers(map) {

  // Marker sizes are expressed as a Size of X,Y where the origin of the image
  // (0,0) is located in the top left of the image.

  // Origins, anchor positions and coordinates of the marker increase in the X
  // direction to the right and in the Y direction down.
  var image = {
    url: '/wp-content/themes/rsparts/images/map-marker-60.png',
    size: new google.maps.Size(60, 60),
    //origin: new google.maps.Point(0, 32),
    //anchor: new google.maps.Point(0, 32) 
  };
  


   var infowindow  = new InfoBubble({
      map: map,
      content: '',
      position: new google.maps.LatLng(-32.0, 179.0),
      shadowStyle: 3,
      padding: 20,
      backgroundColor: 'rgb(255,255,255)',
      borderRadius: 0,
      arrowSize: 10,
      borderWidth: 0,
      minWidth: 500,
      minHeight: 50,
      borderColor: '#2c2c2c',
      disableAutoPan: true,
      hideCloseButton: false,
      arrowPosition: 30,
      backgroundClassName: 'transparent',
      arrowStyle: 0
});

  var loader = new Promise(loadStores);
  loader.then(function(stores){
    for (var i = 0; i < stores.length; i++) {
      var store = stores[i];
      var marker = new google.maps.Marker({
        position: {lat: Number(store.lat), lng: Number(store.long)},
        map: map,
        icon: image,
        title: store.name
      });

      marker_array.push(marker);

      var content = '<div class="infobubble-text js-infobubble-text">' + store.name + '</div>';
      var content2 =  '<h3>' + store.name + '</h3><br>' +  
      '<ul class="ml0">'+
      (store.email?'<li><i class="fa-absolute fa fa-envelope" aria-hidden="true"></i><p><a href="mailto:' + store.email  + '">' + store.email  + '</a></p>':'') + 
      (store.phone?'<li><i class="fa-absolute fa fa-phone" aria-hidden="true"></i><p><a href="tel:' + store.phone  + '">' + store.phone  + '</a></p>':'') + 
      (store.fax?'<li><i class="fa-absolute fa fa-fax" aria-hidden="true"></i><p>' + store.fax  + '</p>':'') + 
      '<li><i class="fa-absolute fa fa-map-marker" aria-hidden="true"></i><p>' + store.address + '<br>' + 
      store.city +  ', ' + 
      store.province + ' ' +
      store.postal_code + '</p></li></ul>';

      if (lang =='fr-CA') {
        button_label_direction = 'Itinéraire';
        button_label_visit = 'Visitez le site web'; 
        link = '/magasin/';
        vlang = '?lang=fr';
      } else {
        button_label_direction = 'Directions';
        button_label_visit = 'Go to website';
        link = '/store/';
        vlang = '';
      }

      if(store.description_en && store.description_en!="null"){
        content2 +='<p>'+store.description_en+'</p>';
      }

      content2 +='<button class="btn" onclick="window.open(\'http://maps.google.com/maps?daddr=' + store.lat + ',' + store.long +'&amp;saddr=\')">' + button_label_direction + '</button>';
      if(store.website_link && store.website_link!="null"){
        content2 +='<a class="btn" href="'+store.website_link+'" target="_blank">' + button_label_visit + '</a>'
      }

      

      google.maps.event.addListener(marker,'click', (function(marker,content,content2,infowindow){ 
        return function() {
          infowindow.setContent(content);
          jQuery('.js-infobubble-text').css('background-color','red');
          jQuery('.js-info-bubble-close').attr('src','/wp-content/themes/rsparts/images/close.png');
          jQuery('.js-modal--store-info').css('display', 'none');
          
          if (content) {
              jQuery('.js-modal--content').html(content2);
              jQuery('.js-modal--store-info').fadeIn()
          }
          
          map.panTo(marker.getPosition());
          infowindow.close();
          infowindow.open(map,marker);
          
        };
      })(marker,content,content2,infowindow));
    }
  })
  var clusterStyles = [
   {
      textColor: 'black',
      url: '/wp-content/themes/rsparts/images/map-marker-60.png',
      height: 60,
      width: 60,
      textSize: 20,
      anchor:[12,-2]

    }
  ];
  var marker_style  = {
    gridSize: 50,
    styles: clusterStyles,
    maxZoom: 15
  };
  var markerCluster = new MarkerClusterer(map, marker_array, marker_style);
 
}




jQuery(document).ready(function(){
  jQuery('.js-close--modal').on('click', function(){
    jQuery('.js-modal--store-info').css('display', 'none');
  });
  jQuery('.js-slider').slider({
    value: 12,
    min: 10,
    max: 14,
  
    //this updates the value of your hidden field when user stops dragging
    change: function(event, ui) {
      zoom = ui.value;
      map.setZoom(zoom);

      if (zoom == 10) {
        jQuery('.js-range').text(100);
      } 
      else if (zoom == 11) {
        jQuery('.js-range').text(75);
      }
       else if (zoom == 12) {
        jQuery('.js-range').text(50);
      }
       else if (zoom == 13) {
        jQuery('.js-range').text(25);
      }
       else if (zoom == 14) {
        jQuery('.js-range').text(0);
      }
   
      
    }
  });

});

function loadStores(resolve,reject){
  var url = "/ajax/getStoreLocator/";
  if (lang =='fr-CA') url += '?lang=fr';

  jQuery.ajax({
    url: url,
    dataType: "text",
    success:function(data){
      data = data.replace('<?php','');
      resolve(JSON.parse(data));
      if(data.startsWith('<?php'))
      console.log(data)
    },
    error:function(a,b,c){
      reject(b);
    }
  })


}

